/* eslint-disable */
import axios from 'axios'
import Qs from 'qs'
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'

//创建 axios 实例
const service = axios.create({
  timeout: 10000,//请求超时时间
  method: 'post',
  baseURL: process.env.VUE_APP_HOST
})
//拦截器
service.interceptors.request.use(

  config => {
    // 加密处理
    let postData = {}, dataSort, postMsg, str;
    if (config.data !== null && config.data !== '') {
      postData = config.data;
    }
    let token = localStorage.getItem('token')
    if (token && token !== '') {
      postData.token = token
    } else {
      postData.token = ''
    }
    dataSort = sortToMap(postData);
    postMsg = sign(postData, dataSort);
    str = Encrypt(postMsg);
    str = md5(str);

    //在发送请求之前做处理……
    config.headers = Object.assign(config.method === 'get' ? {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=UTF-8'
    } : {
      'chuangyi': str,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'token': token
    }, config.headers);


    // 处理data
    if (config.method === 'post') {
      const contentType = config.headers['Content-Type'];
      if (contentType) {
        if (contentType.includes('multipart')) {//类型'multipart/form-data;'
          config.data = data;
        } else if (contentType.includes('json')) {//类型'application/json;'
          //服务器收到 raw body（原始数据）“{name:"nowThen",age:'18'}”(普通字符串)
          config.data = JSON.stringify(config.data)
        } else { // 类型 'application/x-www-form-urlencoded;'
          //服务器收到raw body(原始数据)
          config.data = Qs.stringify(config.data)
        }
      }
    }
    return config
  }, error => {
    // 对请求错误做处理……
    return Promise.reject(error);
  }
);

//实例添加响应拦截器
service.interceptors.response.use(res => {
  //对响应数据做处理，以下根据实际数据结构改动
  return res
}, error => {
  //对响应错误做处理……
  return Promise.reject(error)
})


//自定义方法
/**
 * 加密
 * @param word
 * @returns {string}
 * @constructor
 */
function Encrypt (word) {
  var key = CryptoJS.enc.Utf8.parse("JLS3yTeedOQbLqA3");
  //console.log("key:" + key);
  var srcs = CryptoJS.enc.Utf8.parse(word);
  //console.log("srcs:" + srcs);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.toString();
}

function sortToMap (data) {
  let arr = new Array()
  for (let key in data) {
    arr.push(key);
  }
  arr.sort();
  return arr;
}

/**
 * 参数组装
 * @param map
 * @param arr2
 * @returns {string}
 */
function sign (map, arr2) {
  let str = "";
  for (let i = 0; i < arr2.length; i++) {

    if (map[arr2[i]] === null || map[arr2[i]] === "" || map[arr2[i]] === undefined) {
      continue;
    }
    str += arr2[i] + "=" + encodeURIComponent(map[arr2[i]]) + "&";
  }
  str = str.substring(0, str.length - 1);
  return str;
}


export default service
